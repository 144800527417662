var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonAddEditDialog', {
    attrs: {
      "value": _vm.value,
      "is-edit-mode": _vm.state.isEditMode,
      "is-loading": _vm.state.isLoadingAddEditProfUnit
    },
    on: {
      "close": function close($event) {
        return _vm.listeners.onClose();
      },
      "add": function add($event) {
        _vm.state.isEditMode ? _vm.listeners.onEdit() : _vm.listeners.onAdd();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t("baseData.profUnits.dialog.".concat(_vm.state.isEditMode ? 'edit' : 'add', ".title"))) + " ")];
      },
      proxy: true
    }, {
      key: "fields",
      fn: function fn() {
        var _vm$state$form$parent;

        return [_vm._l(_vm.constants.FORM_FIELDS, function (entry) {
          var _vm$state$form;

          return _c('div', {
            key: entry.value
          }, [entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.TEXT ? [entry.dataTyp === _vm.constants.DATA_TYPE.TEXT ? _c('v-text-field', {
            class: entry.isRequired && 'required',
            attrs: {
              "label": _vm.$t("baseData.profUnits.dialog.form.".concat(entry.value)),
              "rules": [].concat(entry.rules)
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }) : entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.TEXT && entry.dataTyp === _vm.constants.DATA_TYPE.NUMBER ? _c('CommonNumberInput', {
            class: entry.isRequired && 'required',
            attrs: {
              "label": _vm.$t("baseData.profUnits.dialog.form.".concat(entry.value)),
              "rules": [].concat(entry.rules)
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }) : _vm._e()] : entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.DROPDOWN && !((_vm$state$form = _vm.state.form) !== null && _vm$state$form !== void 0 && _vm$state$form.parent) && !_vm.profUnitParentId ? _c('CommonAutocomplete', _vm._b({
            attrs: {
              "items": entry.items.value,
              "loading": entry.isLoading.value,
              "item-text": "name",
              "item-value": "id",
              "label": _vm.$t("baseData.profUnits.dialog.form.".concat(entry.value))
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }, 'CommonAutocomplete', entry.fieldOptions, false)) : _vm._e()], 2);
        }), _vm.state.isEditMode ? _c('div', [_c('v-select', {
          staticClass: "parent-select",
          attrs: {
            "value": (_vm$state$form$parent = _vm.state.form.parent) !== null && _vm$state$form$parent !== void 0 && _vm$state$form$parent.id ? _vm.state.form.parent.id : null,
            "label": _vm.$t("baseData.profUnits.dialog.form.parent"),
            "items": _vm.state.possibleParents
          },
          on: {
            "change": function change($event) {
              _vm.state.form.parent = $event;
            }
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function fn(_ref) {
              var item = _ref.item;
              return [_c('span', {
                style: {
                  'margin-left': item.level * 16 + 'px'
                }
              }, [_vm._v(_vm._s(item.text))])];
            }
          }], null, false, 2291584374)
        })], 1) : _vm._e(), _c('CommonAutocomplete', {
          staticClass: "min-width",
          attrs: {
            "items": _vm.state.profUnitCategory.items,
            "item-text": "name",
            "item-value": "id",
            "loading": _vm.state.profUnitCategory.isLoading,
            "label": _vm.$t('baseData.profUnits.dialog.form.category')
          },
          model: {
            value: _vm.state.form.category,
            callback: function callback($$v) {
              _vm.$set(_vm.state.form, "category", $$v);
            },
            expression: "state.form.category"
          }
        }), _c('span', {
          staticClass: "subtitle-1"
        }, [_vm._v(_vm._s(_vm.$t('baseData.profUnits.dialog.form.relevantFor')) + ":")]), _c('div', {
          staticClass: "d-flex align-center justify-space-between"
        }, [_c('v-checkbox', {
          staticClass: "mr-2",
          attrs: {
            "label": _vm.$t('baseData.profUnits.dialog.form.relevantToUtilization')
          },
          model: {
            value: _vm.state.form.relevantToUtilization,
            callback: function callback($$v) {
              _vm.$set(_vm.state.form, "relevantToUtilization", $$v);
            },
            expression: "state.form.relevantToUtilization"
          }
        }), _c('v-checkbox', {
          staticClass: "ml-2",
          attrs: {
            "label": _vm.$t('baseData.profUnits.dialog.form.relevantToCapacity')
          },
          model: {
            value: _vm.state.form.relevantToCapacity,
            callback: function callback($$v) {
              _vm.$set(_vm.state.form, "relevantToCapacity", $$v);
            },
            expression: "state.form.relevantToCapacity"
          }
        })], 1), _vm.state.isEditMode ? _c('div', _vm._l(_vm.state.pspProfUnitAssignments, function (row) {
          return _c('div', {
            key: row.id,
            staticClass: "d-flex align-center"
          }, [_c('CommonAutocomplete', {
            staticClass: "mr-3",
            attrs: {
              "items": _vm.state.pspAccounts,
              "item-text": "name",
              "item-value": "id",
              "loading": _vm.state.isLoadingPspAccountsBasic,
              "label": _vm.$t('baseData.profUnits.dialog.form.pspAccount'),
              "disabled": true
            },
            model: {
              value: row.pspAccount,
              callback: function callback($$v) {
                _vm.$set(row, "pspAccount", $$v);
              },
              expression: "row.pspAccount"
            }
          }), _c('CommonNumberInput', {
            staticClass: "ml-3",
            attrs: {
              "label": _vm.$t("baseData.profUnits.dialog.form.ratio"),
              "rules": [].concat(_vm.state.ratioRules)
            },
            model: {
              value: row.ratio,
              callback: function callback($$v) {
                _vm.$set(row, "ratio", $$v);
              },
              expression: "row.ratio"
            }
          }), _c('v-btn', {
            staticClass: "mx-1",
            attrs: {
              "icon": "",
              "x-small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.listeners.onDeletePspProfUnitAssignments(row.id);
              }
            }
          }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1)], 1);
        }), 0) : _vm._e(), _vm._l(_vm.state.newPspProfUnitAssignments.length, function (row) {
          return _c('div', {
            key: row.ratio,
            staticClass: "d-flex align-center"
          }, [_c('CommonAutocomplete', {
            staticClass: "mr-3",
            attrs: {
              "items": _vm.state.newPspProfUnitAssignments[row - 1].pspAccount ? _vm.state.pspAccounts : _vm.state.filteredPspAccounts,
              "item-text": "name",
              "item-value": "id",
              "loading": _vm.state.isLoadingPspAccountsBasic,
              "label": _vm.$t('baseData.profUnits.dialog.form.pspAccount'),
              "disabled": _vm.state.newPspProfUnitAssignments[row - 1].pspAccount ? true : false
            },
            model: {
              value: _vm.state.newPspProfUnitAssignments[row - 1].pspAccount,
              callback: function callback($$v) {
                _vm.$set(_vm.state.newPspProfUnitAssignments[row - 1], "pspAccount", $$v);
              },
              expression: "state.newPspProfUnitAssignments[row - 1].pspAccount"
            }
          }), _c('CommonNumberInput', {
            staticClass: "ml-3",
            attrs: {
              "label": _vm.$t("baseData.profUnits.dialog.form.ratio"),
              "rules": [].concat(_vm.state.ratioRules)
            },
            model: {
              value: _vm.state.newPspProfUnitAssignments[row - 1].ratio,
              callback: function callback($$v) {
                _vm.$set(_vm.state.newPspProfUnitAssignments[row - 1], "ratio", $$v);
              },
              expression: "state.newPspProfUnitAssignments[row - 1].ratio"
            }
          }), _c('v-btn', {
            staticClass: "mx-1",
            attrs: {
              "icon": "",
              "x-small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.listeners.onDeleteNewPspProfUnitAssignments(row);
              }
            }
          }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1)], 1);
        }), _c('v-row', {
          staticClass: "d-flex align-center justify-center mt-1"
        }, [!_vm.state.newPspProfUnitAssignments.length && !_vm.state.pspProfUnitAssignments.length ? _c('v-col', {
          staticClass: "mr-auto text-body-1"
        }, [_vm._v(" " + _vm._s(_vm.$t('baseData.profUnits.dialog.form.pspAccount')) + " ")]) : _vm._e(), _c('v-col', {
          staticClass: "ml-auto text-center"
        }, [_c('v-tooltip', {
          staticClass: "mx-auto",
          attrs: {
            "top": "",
            "open-delay": "200"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": _vm.listeners.onAddPspProfUnitAssignments
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")])], 1)];
            }
          }])
        }, [_vm._v(" " + _vm._s(_vm.$t('baseData.profUnits.dialog.form.addPspAccountRow')) + " ")])], 1), !_vm.state.newPspProfUnitAssignments.length && !_vm.state.pspProfUnitAssignments.length ? _c('v-col', {
          staticClass: "ml-auto"
        }) : _vm._e()], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }