import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  UseGetPspProfUnitAssignments,
  UseGetPspProfUnitAssignment,
  UseCreatePspProfUnitAssignment,
  UseDeletePspProfUnitAssignment,
  UseUpdatePspProfUnitAssignment,
  PspProfUnitAssignment,
  NewPspProfUnitAssignment,
  PspProfUnitAssignmentId,
} from '@/api/types/pspProfUnitAssignment'
import { PromiseType } from '@/utils/types/PromiseType'

const VERSION = 'v1'
const RESOURCE = 'psp-professional-unit-assignments'

function useGetPspProfUnitAssignments(): UseGetPspProfUnitAssignments {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetPspProfUnitAssignments['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetPspProfUnitAssignment(): UseGetPspProfUnitAssignment {
  const axios = useAxios<PromiseType<ReturnType<UseGetPspProfUnitAssignment['getPspProfUnitAssignment']>>>({
    method: 'GET',
  })

  function getPspProfUnitAssignment(id: PspProfUnitAssignmentId) {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getPspProfUnitAssignment,
  }
}

function useCreatePspProfUnitAssignment(): UseCreatePspProfUnitAssignment {
  const axios = useAxios<PromiseType<ReturnType<UseCreatePspProfUnitAssignment['createPspProfUnitAssignment']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createPspProfUnitAssignment(data: NewPspProfUnitAssignment): Promise<PspProfUnitAssignment> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createPspProfUnitAssignment,
  }
}

function useUpdatePspProfUnitAssignment(): UseUpdatePspProfUnitAssignment {
  const axios = useAxios<PromiseType<ReturnType<UseUpdatePspProfUnitAssignment['updatePspProfUnitAssignment']>>>({
    method: 'PUT',
  })

  function updatePspProfUnitAssignment(
    id: PspProfUnitAssignmentId,
    data: PspProfUnitAssignment
  ): Promise<PspProfUnitAssignment> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updatePspProfUnitAssignment,
  }
}

function useDeletePspProfUnitAssignment(): UseDeletePspProfUnitAssignment {
  const axios = useAxios<PromiseType<ReturnType<UseDeletePspProfUnitAssignment['deletePspProfUnitAssignment']>>>({
    method: 'DELETE',
  })

  function deletePspProfUnitAssignment(id: PspProfUnitAssignmentId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deletePspProfUnitAssignment,
  }
}

export {
  useGetPspProfUnitAssignments,
  useGetPspProfUnitAssignment,
  useCreatePspProfUnitAssignment,
  useUpdatePspProfUnitAssignment,
  useDeletePspProfUnitAssignment,
}
