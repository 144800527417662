import { BasicEntity } from '@/api/types/misc'
import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { PromiseType } from '@/utils/types/PromiseType'

import {
  NewPspAccount,
  PspAccount,
  PspAccountId,
  UseGetPspAccounts,
  UseGetPspAccountsBasic,
  UseCreatePspAccount,
  UseUpdatePspAccount,
  UseDeletePspAccount,
} from './types/pspAccount'

const VERSION = 'v1'
const RESOURCE = 'psp-accounts'

function useGetPspAccounts(): UseGetPspAccounts {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetPspAccounts['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetPspAccountsBasic(): UseGetPspAccountsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreatePspAccount(): UseCreatePspAccount {
  const axios = useAxios<PromiseType<ReturnType<UseCreatePspAccount['createPspAccount']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createPspAccount(data: NewPspAccount): Promise<PspAccount> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createPspAccount,
  }
}

function useUpdatePspAccount(): UseUpdatePspAccount {
  const axios = useAxios<PromiseType<ReturnType<UseUpdatePspAccount['updatePspAccount']>>>({ method: 'PUT' })

  function updatePspAccount(id: PspAccountId, data: PspAccount): Promise<PspAccount> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updatePspAccount,
  }
}

function useDeletePspAccount(): UseDeletePspAccount {
  const axios = useAxios<PromiseType<ReturnType<UseDeletePspAccount['deletePspAccount']>>>({ method: 'DELETE' })

  function deletePspAccount(id: PspAccountId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deletePspAccount,
  }
}

export { useGetPspAccounts, useGetPspAccountsBasic, useCreatePspAccount, useUpdatePspAccount, useDeletePspAccount }
