
import { defineComponent, reactive, ref, PropType, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'
import { mdiPlus, mdiDelete } from '@mdi/js'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { useNotify } from '@/store'

import { isPercentageRange, isRequired } from '@/utils/validation'
import { isOfType } from '@/utils/types/isOfType'
import { convertCommaIntoDotInput, convertDotIntoCommaInput } from '@/utils/convertInput'
import { handleError } from '@/utils/handleError'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'

import { useCreateProfUnit, useUpdateProfUnit } from '@/api/profUnit'
import {
  useCreatePspProfUnitAssignment,
  useDeletePspProfUnitAssignment,
  useUpdatePspProfUnitAssignment,
} from '@/api/pspProfUnitAssignment'
import { useGetPspAccountsBasic } from '@/api/pspAccount'
import { useGetEnumItemsBasic } from '@/api/enumItem'
import { useGetProfilesBasic } from '@/api/profile'

import { ProfUnitInput, ProfUnitOutput, ProfUnitId, ProfUnitTreeStructure } from '@/api/types/profUnit'
import {
  NewPspProfUnitAssignment,
  PspProfUnitAssignment,
  PspProfUnitAssignmentId,
} from '@/api/types/pspProfUnitAssignment'
import { PROFILE_TYPE } from '@/api/types/profile'

import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'

import { HierarchyItem } from '@/views/baseData/views/types'

export default defineComponent({
  name: 'AddEditProfUnitDialog',
  components: {
    CommonAddEditDialog,
    CommonNumberInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    profUnitParentId: {
      type: Number as PropType<ProfUnitId>,
      default: null,
    },
    treeViewItems: {
      type: Array as PropType<ProfUnitTreeStructure[]>,
      default: null,
    },
    profUnitToEdit: {
      type: Object as PropType<ProfUnitOutput>,
      default: null,
    },
  },
  setup: (props, { emit, root }) => {
    const { addNotification } = useNotify()

    const isEditMode = computed(() => Boolean(props.profUnitToEdit))

    const form = ref<ProfUnitInput | ProfUnitOutput>(
      isEditMode.value ? cloneDeep(props.profUnitToEdit) : ({} as ProfUnitOutput)
    )

    const { exec: getProfilesBasic, data: profilesBasic, isLoading: isLoadingProfilesBasic } = useGetProfilesBasic()
    getProfilesBasic({ params: { type: PROFILE_TYPE.ASSIGNMENT } })

    const FORM_FIELDS: FormField[] = [
      {
        value: 'teamId',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: false,
        rules: [],
      },
      {
        value: 'longName',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('baseData.profUnits.dialog.form.longName') as string)],
      },
      {
        value: 'shortName',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'domain',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'description',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'capacityRelevantProfiles',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        isRequired: false,
        items: computed(() => profilesBasic.value ?? []),
        isLoading: computed(() => isLoadingProfilesBasic.value),
        fieldOptions: [{ multiple: true }],
        rules: [],
      },
    ]

    const {
      exec: getEnumItemsBasic,
      data: enumItemsBasic,
      isLoading: isLoadingEnumItemsBasic,
    } = useGetEnumItemsBasic()
    getEnumItemsBasic({ params: { enumItemAssignment: 'PROFESSIONAL_UNIT_CATEGORY' } })

    const profUnitCategory = {
      items: computed(() => enumItemsBasic.value ?? []),
      isLoading: computed(() => isLoadingEnumItemsBasic.value),
      rules: [],
    }

    props.profUnitParentId && (form.value.parent = props.profUnitParentId)

    const { createProfUnit, isLoading: isLoadingCreateProfUnit } = useCreateProfUnit()

    const { createPspProfUnitAssignment } = useCreatePspProfUnitAssignment()

    const { deletePspProfUnitAssignment } = useDeletePspProfUnitAssignment()

    const { updatePspProfUnitAssignment } = useUpdatePspProfUnitAssignment()

    function filterAndMapPspAccountRows(profUnitId: ProfUnitId): Promise<PspProfUnitAssignment>[] {
      return newPspProfUnitAssignments.value
        .filter((row) => row.ratio && row.pspAccount)
        .map((row) => {
          row.professionalUnit = profUnitId
          row.ratio = convertCommaIntoDotInput(row.ratio).toString()

          return createPspProfUnitAssignment(row)
        })
    }

    async function onAdd(): Promise<void> {
      if (!isOfType<ProfUnitOutput>(form.value, 'id')) {
        try {
          const resProfUnit = await createProfUnit(form.value)

          const pspAccountPromises = filterAndMapPspAccountRows(resProfUnit.id)

          await Promise.all(pspAccountPromises)

          addNotification({
            text: root.$t('misc.added') as string,
            type: 'success',
            timeout: 3000,
          })

          emit('added-edited', resProfUnit)

          onClose()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateProfUnit, isLoading: isLoadingUpdateProfUnit } = useUpdateProfUnit()

    async function onEdit(): Promise<void> {
      if (isOfType<ProfUnitOutput>(form.value, 'id')) {
        try {
          const updatedForm = mapBasicEntityToIdWithForm<ProfUnitOutput>(form.value)

          const resProfUnit = await updateProfUnit(updatedForm.id, updatedForm)

          const pspProfessionalUnitAssignmentsToUpdate = cloneDeep(pspProfUnitAssignments.value)

          pspProfessionalUnitAssignmentsToUpdate.map((row) => {
            if (isOfType<NewPspProfUnitAssignment>(pspProfessionalUnitAssignmentsToUpdate[0], 'ratio')) {
              row.ratio = convertCommaIntoDotInput(row.ratio)
              row.professionalUnit = resProfUnit.id

              updatePspProfUnitAssignment(row.id, row).catch((error: unknown) => {
                handleError(error)
              })
            }
          })

          const pspProfessionalUnitAssignmentsToDelete = cloneDeep(pspProfUnitAssignments.value)

          if (isOfType<ProfUnitOutput>(form.value, 'id')) {
            form.value.pspProfessionalUnitAssignments
              .filter((row) => !pspProfessionalUnitAssignmentsToDelete.some((row2) => row2.id === row.id))
              .map((row) => {
                deletePspProfUnitAssignment(row.id)
              })
          }

          const pspAccountPromises = filterAndMapPspAccountRows((form.value as ProfUnitOutput).id)

          await Promise.all(pspAccountPromises)

          addNotification({
            text: root.$t('misc.edited') as string,
            type: 'success',
            timeout: 3000,
          })

          emit('added-edited', resProfUnit)

          onClose()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditProfUnit = computed(() => isLoadingCreateProfUnit.value || isLoadingUpdateProfUnit.value)

    function onClose(): void {
      form.value = {} as ProfUnitInput

      emit('close')
    }

    const possibleParents = computed(() => {
      const possibleParents: HierarchyItem[] = [{ text: '/', value: null, level: 0 }]
      props.treeViewItems.forEach((item) => {
        addItemPlusChildrenToPossibleParents({
          item: item as ProfUnitTreeStructure,
          origItemId: props.profUnitToEdit.id,
          level: 0,
          possibleParents,
        })
      })
      return possibleParents
    })

    function addItemPlusChildrenToPossibleParents(itemWithChildren: {
      item: ProfUnitTreeStructure
      origItemId: number
      level: number
      possibleParents: HierarchyItem[]
    }) {
      const { item, origItemId, level, possibleParents } = itemWithChildren

      if (item.id === origItemId) {
        return
      }
      possibleParents.push({ text: item.longName, value: item.id, level: level })
      item.profUnits.forEach((child) => {
        addItemPlusChildrenToPossibleParents({
          item: child as ProfUnitTreeStructure,
          origItemId,
          level: level + 1,
          possibleParents,
        })
      })
    }

    const { exec: getPspAccountsBasic, data, isLoading: isLoadingPspAccountsBasic } = useGetPspAccountsBasic()
    const pspAccounts = computed(() => data.value ?? [])
    getPspAccountsBasic()

    const selectedPspAccountIds = computed(() => [
      ...pspProfUnitAssignments.value.map((row) => row.pspAccount),
      ...newPspProfUnitAssignments.value.map((row) => row.pspAccount),
    ])

    const filteredPspAccounts = computed(() =>
      (data.value ?? []).filter((pspAccount) => !selectedPspAccountIds.value.some((id) => id === pspAccount.id))
    )

    const newPspProfUnitAssignments = ref<NewPspProfUnitAssignment[]>([])

    const pspProfUnitAssignments = ref<NewPspProfUnitAssignment[] | PspProfUnitAssignment[]>(
      isOfType<ProfUnitOutput>(form.value, 'id') && form.value.pspProfessionalUnitAssignments.length
        ? form.value.pspProfessionalUnitAssignments
        : []
    )

    if (
      pspProfUnitAssignments.value.length &&
      isOfType<NewPspProfUnitAssignment>(pspProfUnitAssignments.value[0], 'ratio')
    ) {
      pspProfUnitAssignments.value.map((row) => {
        row.ratio = convertDotIntoCommaInput(row.ratio)
        row.professionalUnit = row.professionalUnit.id
        row.pspAccount = row.pspAccount.id
        return row
      })
    }

    function onAddPspProfUnitAssignments() {
      newPspProfUnitAssignments.value.push({} as NewPspProfUnitAssignment)
    }

    function onDeleteNewPspProfUnitAssignments(row: number) {
      newPspProfUnitAssignments.value.splice(row - 1, 1)
    }

    function onDeletePspProfUnitAssignments(pspProfUnitAssignmentId: PspProfUnitAssignmentId) {
      pspProfUnitAssignments.value = isOfType<ProfUnitOutput>(form.value, 'id')
        ? form.value.pspProfessionalUnitAssignments.filter((row) => row.id !== pspProfUnitAssignmentId)
        : []
    }

    function ratioRules(value: string) {
      return isPercentageRange(value)
    }

    return reactive({
      icons: {
        mdiPlus,
        mdiDelete,
      },
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        form,

        isEditMode,

        isLoadingAddEditProfUnit,

        possibleParents,

        pspAccounts,
        filteredPspAccounts,
        pspProfUnitAssignments,
        newPspProfUnitAssignments,
        isLoadingPspAccountsBasic,

        profUnitCategory,

        ratioRules,
      },
      listeners: {
        onAdd,

        onEdit,

        onClose,

        onAddPspProfUnitAssignments,

        onDeleteNewPspProfUnitAssignments,
        onDeletePspProfUnitAssignments,
      },
    })
  },
})
